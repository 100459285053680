import { map, find, uniq, uniqBy, fill, sumBy, sortBy, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand';
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import vendorsArray from '../../components/widgets/filter/enums/vendors';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import { formatChartLabel, formatChartLabelItem, sortDateRange, getWeekDay, getWeekDayWarning } from '../../helpers/DateFormatter';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import { getTime } from '../../helpers/DateFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';
import countriesByAbbreviation from '../../components/common/CountriesByAbbreviation';

export const tracksFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatTop,
    formatDetails,
    formatTracklist,
    formatAutocomplete,
    formatAutocompleteItem,
    formatStatsTimeline,
    formatCard,
    formatStreamsStats,
    formatMetadata,
    formatMetadataFlat,
    formatTimeseries,
    formatCompareTracks,
    formatCompareTrackDemographics,
    formatCompareTrackTerritories,
    formatCompareTrackVendors,
    formatTracksReleaseDates,
    formatTiktokTimeseries,
    formatTiktokTerritories,
    formatTiktokTerritoriesTimeseries,
    formatTiktokMetrics,
    formatTiktokMetricsTimeseries,
    formatCompareTrackArtist,
    formatCompareProductTracks
};

function formatForPercentageChart(data, column){
    let dataset = [], 
    labels = [];

    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return {data: [], total: 0};
    let total = 0;
    
    for(let entry of data) {
        total = entry.total_entities;
        entry.id = entry.track_id;
        entry.name = entry.track_title;
        entry.logo = ImageURL(entry.track_image, entry.product_id, 'tracks');
        if(entry.active && entry.curr_units)
            entry.engaged = Math.round((entry.curr_active / entry.curr_units) * 100);
    };
    //data = AddTotal(data, 'track');
    return {data, total};
}

function dateDiff(item) {
    if(!item.added_at)
        return 'N/A';
    const addedAt = moment(item.added_at),
        removedAt = moment(item.removed_at || undefined);

    
    return Math.round(moment.duration(removedAt.diff(addedAt)).as('days'));
}

function formatTop(data){
    if(!data)
        return [];    
    return map(data, (entry)=>{
        
        const tiktokCreates = find(entry.vendors, vendor=>(vendor.vendor_value == 'TikTok' && vendor.cont_typ == 7));
        const tiktokViews = find(entry.vendors, vendor=>(vendor.vendor_value == 'TikTok' && vendor.cont_typ == 8));
        
        entry.id = entry.track_id;
        //entry.name = entry.track_title+(entry.track_version ? ` (${entry.track_version})` : '');
        entry.code = entry.isrc;
        //entry.image = ImageURL(entry.track_image, entry.product_id, 'tracks');
        entry.absoluteValue = entry.curr_units;
        entry.percentageValue = entry.units_diff = percentageValue(entry.curr_units, entry.prev_units);
        entry.playlists_diff = percentageValue(entry.curr_playlists, entry.prev_playlists);
        entry.engaged = Math.round((entry.active / entry.curr_units) * 100);
        //entry.added_at = entry.added_at ? JSON.parse(entry.added_at).dates.pop() : null;  
        entry.removed_at = entry.removed_at ? entry.removed_at : null;
        entry.vendors = VendorHelper.VendorColors(entry.vendors);
        entry.days_in_list = dateDiff(entry);
        entry.tiktokCreates = tiktokCreates ? tiktokCreates.curr_units : null;
        entry.tiktokViews = tiktokViews ? tiktokViews.curr_units : null;
        entry.units_change = entry.curr_units - entry.prev_units;
        return entry;
    });

}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatTracklist(data){
    let active = [], 
        removed = []; 
    data = data.filter(item=>item.track);
    for(let item of data){
        item.id = item.track_id;
        item.logo = ImageURL(item.image, item.product_id, 'products');
        let artists = [];
        try {
            artists = JSON.parse(item.artists);
        }
        catch (e) {
            //todo: try decoding strings like
            //"---↵- :name: Franz Ferdinand↵  :vendor_id: 0XNa1vTidXlvJ2gHSsRi4A↵"
        };
        item.artists = artists;
        
        if(item.removed_at)
            removed.push(item);
        else
            active.push(item);
    };
    return {active, removed};
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;

    const entity = 'tracks',
        name = hit.name? hit.name : hit._source.title_and_version,
        artist = hit.artist_name? hit.artist_name : hit._source.artist,
        isrc = hit._source.isrc ? `(${hit._source.isrc}) ` : '',
        id = hit.id ? hit.id : hit._id;
    
    return {
        id: id,
        entity,
        name: `${name} ${isrc}(track by ${artist})`,
        logo: icons.entities[entity]
    }
}

function formatAutocompleteItem(hit){
    const entity = 'tracks';
    const artist = hit.display_artist ? hit.display_artist : ( hit.artist_name ? hit.artist_name : '' );
    
    return {
        id: hit.id,
        entity,
        code: hit.isrc,
        first_released: hit.first_released,
        name: `${hit.title}${hit.version ? ' '+ hit.version : ''} ${hit.isrc} (track${artist ? ' by ' + artist : ''})`,
        name_compare: `${artist} - ${hit.title}`,
        name_result: `${artist} - ${hit.title} (${hit.isrc}) released on ${hit.first_released} (${getWeekDay(hit.first_released)})`,
        name_raw: hit.title,
        week_day_warning: getWeekDayWarning(hit.first_released),
        logo: icons.entities[entity],
        image: ImageURL(hit.image, hit.product_id, 'tracks')
    }
}


function formatAutocomplete(data) {
    let {tracks}= data;
    return tracks.map(formatAutocompleteItem);
}

function formatStatsTimelineSum(tracks) {
    let timeline = {};
    for(let track of tracks) {
        if(!track.stms_by_date)
            continue;
        for(let stream of track.stms_by_date) {
            let { stream_date, units } = stream;
            if(timeline[stream_date] === undefined) {
                timeline[stream_date] = 0;
            }
            timeline[stream_date] += units;
        }
    }
    return {
        labels: Object.keys(timeline), 
        datasets: [{
            label: 'Streams',
            data: Object.values(timeline)
        }]
    };
}

function formatStatsTimeline(data) {
    data = data.slice(0, 10);
    const {dates, period} = dateRange(data),
    datasets = data.map(entry=>{
        entry.stms_by_date = entry.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(entry.stms_by_date, {stream_date: date});
            data.push(stms ? stms.units : null);
        }    
            
        return {
            label: entry.track_title,
            data,
            fill: false
        }
    });
    
    const labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    return {labels, datasets}

}


function formatCard(data) {
    data = data.data;
    return {
        id: data.id,
        name: `${data.title} (track)`,
        name_raw: `${data.title}`,
        code: data.isrc,
        logo: ImageURL(data.image, data.product_id, 'tracks'),
        primary_artist: data.primary_artist,
        release_date: data.release_date
    }
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams && metadata) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((track, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, track_id: track.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: track.id,
                label: `${track.title} (${track.isrc}) (track)`,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatMetadata(entities, metadata, imprintMetadata) {
    for(let entity of entities) {
        const metaEntity = find(metadata, {id: entity.id});
        let imprintEntity = {};
        
        if(entity.imprint_id) {
            let imprintName = [];
            for(let imprintId of entity.imprint_id.split(',')) {
                imprintEntity = find(imprintMetadata, {id: Number(imprintId.trim())});
                if(imprintEntity) {
                    imprintName.push(imprintEntity.name);
                }
            }
            if(imprintName.length) {
                entity.imprint = `"${imprintName.join(',')}"`
            }
        }
        if(metaEntity) {
            const artist = metaEntity.primary_artist;
            const displayArtist = metaEntity.display_artist;
            entity.name = entity.track_title = metaEntity.title;
            entity.image = ImageURL(metaEntity.product_image, metaEntity.product_id, 'tracks');
            entity.track_version = metaEntity.version;
            entity.track_genre = metaEntity.primary_genre;
            entity.duration = metaEntity.duration;
            entity.duration_time = getTime(entity.duration);
            entity.artist_id = artist ? artist.id : null;
            entity.artist_name = displayArtist ? displayArtist : ( artist ? artist.name : 'N/A' );
            if(!entity.release_date)
                entity.release_date = metaEntity.release_date;
            entity.original_release_date = metaEntity.original_release_date;

        }
    }
    return entities;

}

function formatMetadataFlat(metadata) {
    let result = []
    for(let row of metadata) {
        result.push({
            id: row.id,
            entity: 'tracks',
            name: row.title,
            code: row.isrc,
            image: ImageURL(row.product_image, row.id, 'tracks'),
            primary_artist: row.primary_artist,
            releaseDate: row.release_date
        });
    }
    return result;
}

function formatTimeseries(tracks, metadata) {
    for(let track of tracks) {
        const metaEntity = find(metadata, {id: track.track_id});
        if(metaEntity) {
            track.name = `${metaEntity.title} (${metaEntity.isrc})`;
        }
        
        track.stms_by_date = track.totals;
    }
        
    return tracks;
}

function formatCompareTracks(idsArray, data) {
    const idsArraySorted = idsArray.split(',');
    
    const ids = sortBy(uniq(map(data, 'isrc')), (id)=>idsArraySorted.indexOf(id)),
        weeks = uniq(map(data, 'weeks_since_release')).sort(),
        weeksCount = weeks.length,
        sources = ['album', 'artist', 'collection', 'other', 'playlist', 'radio', 'search'];
    
    let result = {},
        sourceResult = {};
    
    for(let id of ids) {
        result[id] = {
            streams: fill(Array(weeksCount), null),
            listeners: fill(Array(weeksCount), null),
            ratio: fill(Array(weeksCount), null),
            active: fill(Array(weeksCount), null),
            skipped: fill(Array(weeksCount), null)
        }
        let sourceItem = {};
        for(let source of sources) {
            sourceItem[source] = fill(Array(weeksCount), null);
        }
        sourceResult[id] = sourceItem; 
    }
    for(let item of data) {
        const {isrc, weeks_since_release: week, total_streams, listeners, listener_ratio, active, skipped} = item;
        result[isrc].streams[week] = total_streams;
        result[isrc].listeners[week] = listeners;
        result[isrc].ratio[week] = listener_ratio;
        result[isrc].active[week] = Math.round(active / total_streams * 100);
        result[isrc].skipped[week] = Math.round(skipped / total_streams * 100);
        
        let totalSources = 0,
            maxSourceKey = '',
            maxSource = 0;
        
        for(let source of sources) {
            const sourcePercentage = item[`src_${source}`];
            sourceResult[isrc][source][week] = sourcePercentage;
            totalSources += Math.round(sourcePercentage * 100);
            if(maxSource < sourcePercentage) {
                maxSource = sourcePercentage;
                maxSourceKey = source;
            }
        }
        
        /*
        
        if(totalSources != 10000) {
            
            let maxSourceValue = sourceResult[isrc][maxSourceKey][week],
                offset = (totalSources - 10000)  / 100;
            
            maxSourceValue = Math.round((maxSourceValue - offset) * 100) / 100;
            sourceResult[isrc][maxSourceKey][week] = maxSourceValue;
            
            
            let debugSum = 0;
            for(let source of sources) {
                debugSum += sourceResult[isrc][source][week];
            }
        }
        */
    }
    //const sourceLabels = sources.map(source => capitalize(source));    
    return {datasets: result, labels: weeks, sourceDatasets: sourceResult, sourceLabels: sources, data};
}

function formatCompareTrackDemographics(data) {
    if(!data || !data.length)
        return null;
    
    for(let item of data) {
        let [gender, age] = item.demographic.split(' ');
        item.age = age;
        item.gender = gender;
    }
    
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    let genders = uniq(map(data, 'gender')),
        ages = uniq(map(data, 'age')),
        datasets = {},
        totalStreams = sumBy(data, 'curr_units');
    
    ages.sort();
    genders = sortBy(genders, (gender) => genderOrder.indexOf(gender));

    for(let gender of genders) {
        datasets[gender] = [];
    }
    
    for(let entry of data) {
        datasets[entry.gender][ages.indexOf(entry.age)] = entry.curr_units;
        entry.share = Math.round(entry.curr_units / totalStreams * 100);
    }
    
    datasets = map(datasets, (data, label)=>{
        return {
            data, 
            label, 
        };
    });


    
    return {labels: ages, datasets, data};

}

function formatTracksReleaseDates(tracks, cards, ids) {
    
    let results = [];
    for(let track of tracks.data) {
        let result = {
            id: track.track_id,
            code: track.isrc,
            release_date: track.first_released
        }
        
        
        const card = find(cards, (card => card.data.isrc == track.isrc));
        if(card) {
            result.name_result = `${card.data.primary_artist.name} - ${card.data.title} (${track.isrc}) released on ${track.first_released} (${getWeekDay(track.first_released)})`;
            result.name_compare = `${card.data.title} - ${card.data.primary_artist.name}`;
            result.week_day_warning = getWeekDayWarning(track.first_released);
            result.primary_artist = card.data.primary_artist.name;
            result.primary_artist_id = card.data.primary_artist.id;
        }
        results.push(result);
    }
    const idsArraySorted = ids.split(',');
    results = sortBy(results, (result) => idsArraySorted.indexOf(result.code))
    return results;
}

function formatCompareTrackTerritories(data) {
    for(let itemIndex in data) {
        let item = data[itemIndex];
        item.code = item.territory;
        item.name = item.territory_name;
        item.share = Math.round((item.curr_units / item.total_units) * 100);
        item.rank = ++itemIndex;
    }
    return data;
}

function formatCompareTrackVendors(data) {
    if(!data || !data.length)
        return null;
    
    let datasets = [];
    const weeks = uniq(map(data, 'weeks_since_release')).sort(),
        contentTypes = uniq(map(data, 'content_type')).sort(),
        vendors = uniq(map(data, 'vendor')).sort();
    
    for(let vendorID in vendors) {
        let vendor = vendors[vendorID];
        for(let contentType of contentTypes) {
            const vendorMeta = find(vendorsArray, (vendorItem)=>vendorItem.label == vendor);
            
            const streams = data.filter((item)=>(item.vendor == vendor && item.content_type == contentType));
            if(streams.length) {
                let color = colors[vendorID];
                if(vendorMeta && vendorMeta.hex[contentType])
                    color = vendorMeta.hex[contentType];
                    
                let dataset = fill(Array(weeks.length), 0);
                
                for(let stream of streams) {
                    dataset[stream.weeks_since_release] = stream.curr_units;
                }
                datasets.push({
                    id: `${vendor}_${contentType}`,
                    label: `${vendor} (${contentType})`,
                    data: dataset,
                    source: contentType,
                    borderColor: color,
                    backgroundColor: color,
                    pointBorderColor: color,
                    pointBackgroundColor: color            
                })
                
            }
        }

        
        
        
    }
    return {labels: weeks, datasets, data};
}


function formatTiktokTimeseries(streams, metadata) {
    let labels = [],
        datasets = {views:[], creates:[]},
        exportData = {views:[], creates:[]},
        vendors: [];
    if(streams) {
        const {dates, period} = dateRange(streams);
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
        let vendorIDs = [];
        
        for(let index in metadata) {
            
            const track = metadata[index];
            const row = find(streams, stream=>stream.track_id == track.id);
            if(!row)
                continue; 
            let vendors = uniq(map(row.stms_by_date, 'vend_id'));
            vendorIDs = vendorIDs.concat(vendors);
            
            let totals = {views: [], creates: []};
            
            for(let vendor of vendors) {
                let views = [], creates = [];    
                for(let dateIndex in dates) {
                    const date = dates[dateIndex];
                    const view = find(row.stms_by_date, stmt=>(stmt.stream_date == date && stmt.cont_typ == 8 && stmt.vend_id == vendor));
                    const create = find(row.stms_by_date, stmt=>(stmt.stream_date == date && stmt.cont_typ == 7 && stmt.vend_id == vendor));
                    views.push(view ? view.curr_units : null);
                    creates.push(create ? create.curr_units : null);
                    
                    if(!totals.views[dateIndex])
                        totals.views[dateIndex] = 0;
                    totals.views[dateIndex] += view ? view.curr_units : 0;
                    
                    if(!totals.creates[dateIndex])
                        totals.creates[dateIndex] = 0;
                    totals.creates[dateIndex] += create ? create.curr_units : 0;                    
                }
                
                const vendorMeta = find(vendorsArray, (vendorItem)=>vendorItem.vend_id == vendor);
            
                datasets.views.push({
                    id: `${track.id}${vendor}`,
                    isrc: track.isrc,
                    label: `${track.title} (${track.isrc})`,
                    data: views,
                    vendor,
                    fill: false,
                });            
                datasets.creates.push({
                    id: `${track.id}${vendor}`,
                    isrc: track.isrc,
                    label: `${track.title} (${track.isrc})`,
                    data: creates,
                    vendor,
                    fill: false,
                });
            }
            datasets.views.push({
                id: `${track.id}Total`,
                isrc: track.isrc,
                label: `${track.title} (${track.isrc})`,
                data: totals.views,
                vendor: 0,
                fill: false,
            });            
            datasets.creates.push({
                id: `${track.id}Total`,
                isrc: track.isrc,
                label: `${track.title} (${track.isrc})`,
                data: totals.creates,
                vendor: 0,
                fill: false,
            });
            
            
        };
        
        for(let labelId in labels) {
            let label = labels[labelId];
            let exportViewsRow = {"Period":label};
            let exportCreatesRow = {"Period":label};
            for(let datasetId in datasets.views) {
                const viewsDataset = datasets.views[datasetId];
                const createsDataset = datasets.creates[datasetId];
                
                exportViewsRow[viewsDataset.isrc] = viewsDataset.data[labelId];
                exportCreatesRow[createsDataset.isrc] = createsDataset.data[labelId];
            }
            exportData.views.push(exportViewsRow);
            exportData.creates.push(exportCreatesRow);
        } 
        vendorIDs = uniq(vendorIDs);
        vendors = vendorIDs.map(vendorID=>find(vendorsArray, vendor=>vendor.vend_id == vendorID));
    }

    return { labels, datasets, exportData, vendors };
}

function formatTiktokTerritories(territories) {
    let result = [], vendorIDs = [];
    for(let territory of territories) {
        const country = find(countriesByAbbreviation, country=>country.abbreviation == territory.territory);
        for (let contentType of ['views', 'creates']) {
            for(let vendor of territory.vendors) {
                vendorIDs.push(vendor.vend_id);
                const diff = vendor[`curr_${contentType}`] - vendor[`prev_${contentType}`];
                result.push({
                    contentType: contentType,
                    code: territory.territory,
                    country: country ? country.country : 'N/A',
                    vendor: vendor.vend_id,
                    prev_units: vendor[`prev_${contentType}`],
                    curr_units: vendor[`curr_${contentType}`],
                    units_change: diff,
                    units_diff: Math.round(diff / vendor[`curr_${contentType}`] * 10000) / 100
                })
            }
            const totalPrevUnits = sumBy(territory.vendors, `prev_${contentType}`);
            const totalCurrUnits = sumBy(territory.vendors, `curr_${contentType}`);
            result.push({
                contentType: contentType,
                code: territory.territory,
                country: country ? country.country : 'N/A',
                vendor: 0,
                prev_units: totalPrevUnits,
                curr_units: totalCurrUnits,
                units_change: totalCurrUnits - totalPrevUnits,
                units_diff: Math.round((totalCurrUnits - totalPrevUnits) / totalCurrUnits * 10000) / 100
            })
        }
    }
    vendorIDs = uniq(vendorIDs);
    const vendors = vendorIDs.map(vendorID=>find(vendorsArray, vendor=>vendor.vend_id == vendorID));
    return { result, vendors};
}

function formatTiktokTerritoriesTimeseries(streams) {
    let labels = [],
        datasets = {views:[], creates:[]},
        exportData = {views:[], creates:[]},
        vendors: [];
    const territories = map(streams, 'territory');
    
    if(streams) {
        const {dates, period} = dateRange(streams);
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
        let vendorIDs = [];
        
        for(let index in territories) {
            
            const territory = territories[index];
            const territoryMeta = find(countriesByAbbreviation, {abbreviation: territory});
            
            const row = find(streams, stream=>stream.territory == territory);
            if(!row)
                continue;
            
            let vendors = uniq(map(row.stms_by_date, 'vend_id'));
            vendorIDs = vendorIDs.concat(vendors);
            
            let totals = {views: [], creates: []};
            
            for(let vendor of vendors) {
                let views = [], creates = [];    
                for(let dateIndex in dates) {
                    const date = dates[dateIndex];
                    const view = find(row.stms_by_date, stmt=>(stmt.stream_date == date && stmt.cont_typ == 8 && stmt.vend_id == vendor));
                    const create = find(row.stms_by_date, stmt=>(stmt.stream_date == date && stmt.cont_typ == 7 && stmt.vend_id == vendor));
                    views.push(view ? view.curr_units : null);
                    creates.push(create ? create.curr_units : null);
                    
                    if(!totals.views[dateIndex])
                        totals.views[dateIndex] = 0;
                    totals.views[dateIndex] += view ? view.curr_units : 0;
                    
                    if(!totals.creates[dateIndex])
                        totals.creates[dateIndex] = 0;
                    totals.creates[dateIndex] += create ? create.curr_units : 0;                    
                }
                
                
            
                datasets.views.push({
                    id: `${territory}${vendor}`,
                    code: territory,
                    label: `${territoryMeta.country}`,
                    data: views,
                    vendor,
                    fill: false,
                });            
                datasets.creates.push({
                    id: `${territory}${vendor}`,
                    code: territory,
                    label: `${territoryMeta.country}`,
                    data: creates,
                    vendor,
                    fill: false,
                });
            }
            datasets.views.push({
                id: `${territory}Total`,
                code: territory,
                label: `${territoryMeta.country}`,
                data: totals.views,
                vendor: 0,
                fill: false,
            });            
            datasets.creates.push({
                id: `${territory}Total`,
                code: territory,
                label: `${territoryMeta.country}`,
                data: totals.creates,
                vendor: 0,
                fill: false,
            });
            
            
        };
        
        for(let labelId in labels) {
            let label = labels[labelId];
            let exportViewsRow = {"Period":label};
            let exportCreatesRow = {"Period":label};
            for(let datasetId in datasets.views) {
                const viewsDataset = datasets.views[datasetId];
                const createsDataset = datasets.creates[datasetId];
                
                exportViewsRow[viewsDataset.code] = viewsDataset.data[labelId];
                exportCreatesRow[createsDataset.code] = createsDataset.data[labelId];
            }
            exportData.views.push(exportViewsRow);
            exportData.creates.push(exportCreatesRow);
        } 
        vendorIDs = uniq(vendorIDs);
        vendors = vendorIDs.map(vendorID=>find(vendorsArray, vendor=>vendor.vend_id == vendorID));
    }

    return { labels, datasets, exportData, vendors };
}


function formatTiktokMetrics(tracks, metadata) {
    let vendorIDs = [],
        pie = {};
    for(let track of tracks) {
        const { vendors, track_id } = track;

        let totalRow = {
                curr_units: sumBy(vendors, 'curr_units'),
                views: sumBy(vendors, 'views'),
                creates: sumBy(vendors, 'creates'),
                vend_id: 0,
                likes: sumBy(vendors, 'likes'),
                saves: sumBy(vendors, 'saves'),
                shares: sumBy(vendors, 'shares')
        };
        vendorIDs = vendorIDs.concat(map(vendors, 'vend_id'));
        vendors.push(totalRow);
        
        const trackMeta = find(metadata, item => item.id == track_id);
        
        if(trackMeta) {
            const artist = trackMeta.primary_artist;
            track.name = trackMeta.title;
            track.isrc = trackMeta.isrc;
            track.image = ImageURL(trackMeta.product_image, trackMeta.product_id, 'tracks');
            track.artist = artist ? artist.name : 'Unknown Artist';
        }
        else {
            track.name = 'N/A';
            track.isrc = 'N/A';
        }
    };
    vendorIDs = uniq(vendorIDs);
    const vendors = vendorIDs.map(vendorID=>find(vendorsArray, vendor=>vendor.vend_id == vendorID));
    return { table: tracks, vendors };
}

function formatCompareTrackArtist(data) {
    const entities = uniq(map(data, 'entity_name')).sort(),
        weeks = uniq(map(data, 'weeks_since_release')).sort();
    
    let datasets = [];
    for(let entity of entities) {
        let dataset = [];
        for(let week of weeks) {
            let stream = find(data, item=>(item.entity_name == entity && item.weeks_since_release == week));
            dataset.push(stream ? stream.curr_units : null)
        }
        datasets.push({data: dataset, label: capitalize(entity)});
    }    
    return {labels: weeks, datasets, data};
}

function formatCompareProductTracks(data, metadata) {
    let keys = ['curr_units', 'skipped_ratio', 'collection_ratio', 'engagement_ratio', 'playlist_ratio'];
    let hasDisks = uniq(map(metadata, 'disk')).length > 1;
    let datasets = {};
    let labels = [];
    let titles = [];
    for(let key of keys) {
        datasets[key] = [];
    }
    for(let track of metadata) {
        let row = find(data, item=>item.isrc == track.isrc);
        if(!row) 
            row = {};
        for(let key of keys) {
            datasets[key].push(row[key] || null)
        }
        labels.push(`Track ${track.position} ${hasDisks ? `(Disk ${track.disk})` : '' }`)
        titles.push(track.title)
    }
    
    return {labels, datasets, titles, data};
}

function formatTiktokMetricsTimeseries(streams) {
    let labels = [],
        datasets = {views:[], creates:[]};
    
    const vendors = uniq(map(streams, 'vend_id'));
        
    if(streams) {
        const {dates, period} = dateRange(streams);
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
        
        for(let content of [7,8]) {
            const contentKey = content == 7 ? 'creates' : 'views'
            for(let vendor of vendors) {
                const vendorName = find(vendorsArray, vend=>vend.vend_id == vendor);
                
                const row = find(streams, stream=>stream.vend_id == vendor && stream.cont_typ == content);
                if(!row)
                    continue; 
                let dataset = [];
                for(let date of dates) {
                    let day = find(row.stms_by_date, stmt=>stmt.stream_date == date);
                    dataset.push(day?day.curr_units:null)
                }
                datasets[contentKey].push({
                     id: `${content}_${vendor}`,
                     label: `${vendorName ? vendorName.value : 'N/A'}`,
                     data: dataset,
                     fill: false,
                })
            }    
            
        };
    }
    
    return { labels, datasets };


}