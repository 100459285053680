import { map, find, uniqBy, sortBy, uniq, fill, sumBy, capitalize } from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand';
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import vendorsArray from '../../components/widgets/filter/enums/vendors';
import { formatChartLabel, formatChartLabelItem, sortDateRange, getWeekDay, getWeekDayWarning } from '../../helpers/DateFormatter';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';

export const projectsFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatTop,
    formatDetails,
    formatForTableOld,
    formatAutocomplete,
    formatAutocompleteItem,
    formatCard,
    formatStreamsStats,
    formatStreamsStatsByIDs,
    formatTracks,
    formatMetadata,
    formatMetadataFlat,
    formatMetadataByIDs,
    formatTimeseries,
    formatCompareProjects,
    formatCompareProjectDemographics,
    formatCompareProjectTerritories,
    formatCompareProjectVendors,
    formatProjectsReleaseDates,
    formatCompareProjectArtist
};

function formatForPercentageChart(data, column){
    let dataset = [], 
        labels = [];
    
    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return {data: [], total: 0};
    let total = 0;
    
    for(let entry of data) {
        total = entry.total_entities;
        entry.id = entry.project_id;
        entry.name = entry.project_title;
        if(entry.active && entry.curr_units)
            entry.engaged = Math.round((entry.curr_active / entry.curr_units) * 100);
    }
    //data = AddTotal(data, 'project');
    return {data, total};
}

function formatTop(data){
    if(!data)
        return [];    
    return map(data, (entry)=>{
        entry.id = entry.project_id;
        //entry.name = entry.project_title+(entry.project_version ? ` (${entry.project_version})` : '');
        entry.code = entry.upc;
        //entry.image = ImageURL(entry.project_image, entry.id, 'projects');
        entry.absoluteValue = entry.curr_units;
        entry.percentageValue = entry.units_diff = percentageValue(entry.curr_units, entry.prev_units);
        entry.playlists_diff = percentageValue(entry.curr_playlists, entry.prev_playlists);
        entry.engaged = Math.round((entry.active / entry.curr_units) * 100);
        entry.vendors = VendorHelper.VendorColors(entry.vendors);
        return entry;
    });

}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatForTableOld(data){
    if(!data)
        return [];
    for(let entry of data.data) {
        entry.name = entry.title;
        entry.logo = entry.image;
    }
    return data;
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;
    
    const entity = 'projects',
        name = hit.name? hit.name : hit._source.title,
        artist = hit.artist_name? hit.artist_name : hit._source.artist,
        id = hit.id ? hit.id : hit._id,
        barcode = hit._source.barcode ? `(${hit._source.barcode}) ` : '';

    return {
        id,
        entity,
        name: `${name} ${barcode}(project by ${artist})`,
        logo: icons.entities[entity],
        image: ImageURL(hit.packshot_file_name, hit.id, 'projects')
    };
}

function formatAutocompleteItem(hit){
    const entity = 'projects';
    const artist = hit.display_artist ? hit.display_artist : ( hit.artist_name ? hit.artist_name : '' );
        
    return {
        id: hit.id,
        entity,
        code: hit.identifier,
        name: `${hit.title}${hit.version ? ' '+ hit.version : ''} ${hit.identifier} (project${artist ? ' by ' + artist : ''})`,
        name_compare: `${artist} - ${hit.title}`,
        name_raw: hit.title,
        name_result: `${artist} - ${hit.title} (${hit.identifier}) released on ${hit.first_released} (${getWeekDay(hit.first_released)})`,
        logo: icons.entities[entity],
        image: ImageURL(hit.image, hit.primary_product_id, 'projects'),
        first_released: hit.first_released,
        week_day_warning: getWeekDayWarning(hit.first_released)
    }
}


function formatAutocomplete(data) {
    let {projects}= data;
    return projects.map(formatAutocompleteItem);
}

function formatCard(data) {
    //todo
    data = data.data;
    return {
      id: data.id,
      name: `${data.title} (project)`,
      name_raw: `${data.title}`,
      code: data.barcode,
      logo: data.packshot_file_name,
      primary_artist: data.primary_artist,
      release_date: data.release_date
    };
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [],
        datasetPeriod = '';
    if(streams && metadata) {
        const {dates, period} = dateRangeFlat(streams);
        datasetPeriod = period;
        datasets = metadata.map((projectEntry, index)=>{
            let project = projectEntry.project;
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, project_id: project.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: project.id,
                label: `${project.title} (${project.barcode}) (project)`,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets, datasetPeriod };
}

function formatStreamsStatsByIDs(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams && metadata) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((project, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, project_id: project.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: project.id,
                label: `${project.title} (${project.identifier}) (project)`,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatTracks(projects) {
    let tracks = [];
    for (let project of projects) {
        for (let track of project.tracks) { 
            tracks.push({
                internal_id: track.id,
                value: track.isrc,
                label: `${track.title} (${track.isrc}) (from ${project.title})`
            })
        }
    }
    return tracks;
}

function formatMetadata(entities, metadata) {
    for(let entity of entities) {
        const metaEntity = find(metadata, meta=>meta.id==entity.id);
        if(metaEntity) {
            //const artist = metaEntity.artists ? metaEntity.artists[0].artist : {};
            
            entity.name = entity.project_title = metaEntity.title;
            entity.code = metaEntity.identifier,
            //entity.project_version = metaEntity.project.version;
            //entity.project_genre = metaEntity.project.primary_genre;
            entity.original_release_date = metaEntity.first_release_date;
            if(!entity.release_date)
                entity.release_date = metaEntity.first_release_date;
            
            
            entity.image = ImageURL(metaEntity.image, metaEntity.primary_product_id, 'projects');
            entity.artist_id = metaEntity.artist_id;
            entity.artist_name = metaEntity.artist_name;
        }
    }
    return entities;

}

function formatMetadataFlat(metadata) {
    let result = []
    for(let row of metadata) {
        result.push({
            id: row.id,
            entity: 'projects',
            name: row.title,
            code: row.identifier,
            image: ImageURL(row.image, row.primary_product_id, 'projects'),
            primary_artist: row.artist_name,
            releaseDate: row.first_release_date
        });
    }
    return result;
}


function formatMetadataByIDs(entities, metadata) {
    //todo
    for(let entity of entities) {
        const metaEntity = find(metadata, meta=>meta.id==entity.id);
        if(metaEntity) {
            entity.name = entity.project_title = metaEntity.title;
            entity.original_release_date = metaEntity.first_release_date;
            entity.release_date = metaEntity.first_release_date;
            entity.image = ImageURL(metaEntity.image, entity.primary_product_id, 'projects');
            entity.artist_id = artist_id;
            entity.artist_name = artist_name;
        }
    }
    return entities;

}


function formatTimeseries(projects, metadata) {
    for(let project of projects) {
        
        const metaEntity = find(metadata, meta=>meta.project.id==project.project_id);
        if(metaEntity) {
            project.name = `${metaEntity.project.title} (${metaEntity.project.barcode})`;
        }
        project.stms_by_date = project.totals;
    }
        
    return projects;
}

function formatCompareProjects(idsArray, data) {
    const idsArraySorted = idsArray.split(',');
    
    const ids = sortBy(uniq(map(data, 'identifier')), (id)=>idsArraySorted.indexOf(id)),
        weeks = uniq(map(data, 'weeks_since_release')).sort(),
        weeksCount = weeks.length,
        sources = ['album', 'artist', 'collection', 'other', 'playlist', 'radio', 'search'];
    
    let result = {},
        sourceResult = {};
    
    for(let id of ids) {
        result[id] = {
            streams: fill(Array(weeksCount), null),
            listeners: fill(Array(weeksCount), null),
            ratio: fill(Array(weeksCount), null),
            active: fill(Array(weeksCount), null),
            skipped: fill(Array(weeksCount), null)
        }
        let sourceItem = {};
        for(let source of sources) {
            sourceItem[source] = fill(Array(weeksCount), null);
        }
        sourceResult[id] = sourceItem; 
    }
    for(let item of data) {
        const {identifier, weeks_since_release: week, total_streams, listeners, listener_ratio, active, skipped} = item;
        result[identifier].streams[week] = total_streams;
        result[identifier].listeners[week] = listeners;
        result[identifier].ratio[week] = listener_ratio;
        result[identifier].active[week] = Math.round(active / total_streams * 100);
        result[identifier].skipped[week] = Math.round(skipped / total_streams * 100);
        
        let totalSources = 0,
            maxSourceKey = '',
            maxSource = 0;
        
        for(let source of sources) {
            const sourcePercentage = item[`src_${source}`];
            sourceResult[identifier][source][week] = sourcePercentage;
            totalSources += Math.round(sourcePercentage * 100);
            if(maxSource < sourcePercentage) {
                maxSource = sourcePercentage;
                maxSourceKey = source;
            }
        }
        
    }
    //const sourceLabels = sources.map(source => capitalize(source));    
    return {datasets: result, labels: weeks, sourceDatasets: sourceResult, sourceLabels: sources, data};
}

function formatCompareProjectDemographics(data) {
    if(!data || !data.length)
        return null;
    
    for(let item of data) {
        let [gender, age] = item.demographic.split(' ');
        item.age = age;
        item.gender = gender;
    }
    
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    let genders = uniq(map(data, 'gender')),
        ages = uniq(map(data, 'age')),
        datasets = {},
        totalStreams = sumBy(data, 'curr_units');
    
    ages.sort();
    genders = sortBy(genders, (gender) => genderOrder.indexOf(gender));

    for(let gender of genders) {
        datasets[gender] = [];
    }
    
    for(let entry of data) {
        datasets[entry.gender][ages.indexOf(entry.age)] = entry.curr_units;
        entry.share = Math.round(entry.curr_units / totalStreams * 100);
    }
    
    datasets = map(datasets, (data, label)=>{
        return {
            data, 
            label, 
        };
    });


    
    return {labels: ages, datasets, data};

}

function formatProjectsReleaseDates(projects, ids) {
    let results = [];
    for(let row of projects) {
        let project = row.data;
        
        let result = {
            id: project.id,
            code: project.identifier,
            release_date: project.first_released,
            name_result: `${project.artist_name} - ${project.title} (${project.identifier}) released on ${project.first_released} (${getWeekDay(project.first_released)})`,
            name_compare: `${project.title} - ${project.artist_name}`,
            week_day_warning: getWeekDayWarning(project.first_released),
            primary_artist: project.artist_name,
            primary_artist_id: project.artist_id
            
        }
        
        
        results.push(result);
    }
    const idsArraySorted = ids;
    results = sortBy(results, (result) => idsArraySorted.indexOf(result.code))
    return results;
}

function formatCompareProjectTerritories(data) {
    for(let itemIndex in data) {
        let item = data[itemIndex];
        item.code = item.territory;
        item.name = item.territory_name;
        item.share = Math.round((item.curr_units / item.total_units) * 100);
        item.rank = ++itemIndex;
    }
    return data;
}

function formatCompareProjectVendors(data) {
    if(!data || !data.length)
        return null;
    
    let datasets = [];
    const weeks = uniq(map(data, 'weeks_since_release')).sort(),
        contentTypes = uniq(map(data, 'content_type')).sort(),
        vendors = uniq(map(data, 'vendor')).sort();
    
    for(let vendorID in vendors) {
        let vendor = vendors[vendorID];
        for(let contentType of contentTypes) {
            const vendorMeta = find(vendorsArray, (vendorItem)=>vendorItem.label == vendor);
            
            const streams = data.filter((item)=>(item.vendor == vendor && item.content_type == contentType));
            if(streams.length) {
                let color = colors[vendorID];
                if(vendorMeta && vendorMeta.hex[contentType])
                    color = vendorMeta.hex[contentType];
                    
                let dataset = fill(Array(weeks.length), 0);
                
                for(let stream of streams) {
                    dataset[stream.weeks_since_release] = stream.curr_units;
                }
                datasets.push({
                    id: `${vendor}_${contentType}`,
                    label: `${vendor} (${contentType})`,
                    data: dataset,
                    source: contentType,
                    borderColor: color,
                    backgroundColor: color,
                    pointBorderColor: color,
                    pointBackgroundColor: color            
                })
                
            }
        }

        
        
        
    }
    return {labels: weeks, datasets, data};
}

function formatCompareProjectArtist(data) {
    const entities = uniq(map(data, 'entity_name')).sort(),
        weeks = uniq(map(data, 'weeks_since_release')).sort();
    
    let datasets = [];
    for(let entity of entities) {
        let dataset = [];
        for(let week of weeks) {
            let stream = find(data, item=>(item.entity_name == entity && item.weeks_since_release == week));
            dataset.push(stream ? stream.curr_units : null)
        }
        datasets.push({data: dataset, label: capitalize(entity)});
    }    
    return {labels: weeks, datasets, data};
}
